import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logoutUserAction } from "../users/userSlices";
const baseURL = process.env.REACT_APP_BASE_URL;

export const fetchCustomersAction = createAsyncThunk(
  "customer/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("info", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/customer`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      if (error?.response?.status === 500) {
        // Token is expired or invalid, trigger the logout action
        dispatch(logoutUserAction());
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createCustomerAction = createAsyncThunk(
  "customer/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("info", info);

      const newArray = info?.map((x) =>
        Object.fromEntries(
          Object.entries(x).map(([key, value]) =>
            value === null || value === undefined ? [key, "-"] : [key, value]
          )
        )
      );
      const payload = {
        customer: [
          {
            email: newArray?.map((x) => x?.email),
            first_name: newArray?.map((x) => x?.first_name),
            last_name: newArray?.map((x) => x?.last_name),
            phone: newArray?.map((x) => x?.phone),
            address: newArray?.map((x) => x?.address),
            city: newArray?.map((x) => x?.city),
            country: newArray?.map((x) => x?.country),
            zip: newArray?.map((x) => x?.zip),
            last_order_id: newArray?.map((x) => x?.last_order_id),
            total_spent: newArray?.map((x) => x?.total_spent),
            shopify_id: newArray?.map((x) => x?.shopify_id),
          },
        ],
      };
      console.log("p", payload);
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      axios
        .post(
          `${baseURL}/api/customer`,
          {
            email: payload?.customer[0]?.email,
            first_name: payload?.customer[0]?.first_name,
            last_name: payload?.customer[0]?.last_name,
            shopify_id: payload?.customer[0]?.shopify_id,
            phone: payload?.customer[0]?.phone,
            address: payload?.customer[0]?.address,
            city: payload?.customer[0]?.city,
            country: payload?.customer[0]?.country,
            zip: payload?.customer[0]?.zip,
            total_spent: payload?.customer[0]?.total_spent,

            last_order_id: payload?.customer[0]?.last_order_id,
            user: user,
          },
          config
        )
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchCustomersByDbAction = createAsyncThunk(
  "customer/fetchDb",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("fetchDb", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/customer/db`,

        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const postCustomersAction = createAsyncThunk(
  "customer/post",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("code", info);

      // Example usage

      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/customer/db`,
        {
          first_name: info.first_name,
          last_name: info.last_name,
          email: info.email,
          address: info.address,
          city: info.city,
          country: info.country,

          phone: info.phone,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const customerSlices = createSlice({
  name: "customer",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(postCustomersAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(postCustomersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.customer = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(postCustomersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(createCustomerAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createCustomerAction.fulfilled, (state, action) => {
      state.loading = false;
      state.customers = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createCustomerAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchCustomersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.customers = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchCustomersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    builder.addCase(fetchCustomersByDbAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomersByDbAction.fulfilled, (state, action) => {
      state.loading = false;
      state.dbCustomers = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchCustomersByDbAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default customerSlices.reducer;
