import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const fetchOrdersAction = createAsyncThunk(
  "order/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("info", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/order`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getOrdersAction = createAsyncThunk(
  "order/get",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("info", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/order/dborder`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const createOrderAction = createAsyncThunk(
  "order/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("code", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("ccc", userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.post(
        `${baseURL}/api/order`,
        {
          customer: info?.customer,
          order_date: info?.order_date,
          product_discount: info?.product_discount,
          product_quantity: info?.product_quantity,
          products: info?.products,
          total_price: info?.total_price,

          product_original_price: info?.product_original_price,
          product_newprice: info?.product_newprice,
          product_name: info?.product_name,
          sku: info?.sku,
          payment_type: info?.payment_type,
          salesman: info?.salesman,
          adjusted_price: info?.adjusted_price,
          paid_price: info?.paid_price,
          recieved_price:
            info?.payment_type === "Card"
              ? info?.paid_price
              : info?.recieved_price,
          change_price: info?.payment_type === "Card" ? 0 : info?.change_price,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getOrderAction = createAsyncThunk(
  "order/id/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("sliceid", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/order/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateFBROrderAction = createAsyncThunk(
  "order/idfbr/put",

  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("sliceid", info);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/order/fbr/${info?.id}`,
        {
          Items: info?.Items,
          order_date: info?.order_date,
          status: info?.status,
          TotalBillAmount: info?.TotalBillAmount,
          TotalQuantity: info?.TotalQuantity,
          TotalSaleValue: info?.TotalSaleValue,
          customer: info?.customer,
          TotalTaxCharged: info?.TotalTaxCharged,
          Discount: info?.adjusted_price,
          title: info.title,
          payment_type: info?.payment_type,
          id: info?.id,
        },
        config
      );
      console.log("res idfbr", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateOrderAction = createAsyncThunk(
  "order/update",

  async (orders, { rejectWithValue, getState, dispatch }) => {
    console.log("sliceorders", orders);
    const oldOrder = orders[0];
    const info = orders[1];
    const adjustedPrice = orders[2];
    const totalBill = orders[3];
    const payable = orders[4];
    const status = orders[5];
    const recieved_price = orders[6];

    const change_price = orders[7];

    console.log("oldOrder", oldOrder);

    console.log("updateOrder", info);

    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      //  New Products Added in New Order: bodyProducts
      const bodyProducts = info?.products;
      console.log("bodyProducts", bodyProducts);
      const product_quantity = info?.product_quantity;
      console.log("q1", product_quantity);
      const barcodes = bodyProducts.map((item) => item[3]);
      console.log("b1", barcodes);
      const variant_price = bodyProducts?.map((item) => item[7]);
      console.log("variant_price", variant_price);
      const variant_cost = bodyProducts?.map((item) => item[9]);
      console.log("variant_cost", variant_cost);
      const product_title = bodyProducts?.map((item) => item[0]);
      console.log("product_title", product_title);
      const inventory_item_id = bodyProducts.map((item) => item[10]);
      console.log("inventory_item_id", inventory_item_id);
      const sku = bodyProducts.map((item) => item[11]);
      console.log("sku", sku);
      const origPrice = bodyProducts.map((item) => item[7]);
      console.log("origPrice", origPrice);
      console.log("inventory_item_id", inventory_item_id);
      const product_discount = info?.product_discount;

      const oldTitle = oldOrder?.map((item) => item.title);

      const oldBarcodes = oldOrder?.map((item) => item.barcode);
      const oldsku = oldOrder?.map((item) => item.sku);
      const oldorigPrice = oldOrder?.map((item) => item.variant_price);

      const oldquantity = oldOrder?.map((item) => item.quantity);
      const olddiscount = oldOrder?.map((item) => item.discount);
      const oldcost = oldOrder?.map((item) => item.variant_cost);
      const oldprice = oldOrder?.map((item) => item.variant_price);

      const oldInventoryIDS = oldOrder?.map(
        (item) => item.variant_inventory_item_id
      );

      // NEW ORDER DATA //
      const allTitles = [...product_title, ...oldTitle];
      const allSkus = [...sku, ...oldsku];
      const allPrices = [...origPrice, ...oldorigPrice];

      const allbarcodes = [...barcodes, ...oldBarcodes];
      const allquantities = [...product_quantity, ...oldquantity];
      const alldiscounts = [...product_discount, ...olddiscount];

      const allInventoryItem = [...inventory_item_id, ...oldInventoryIDS];

      const allvariantcost = [...variant_cost, ...oldcost];
      const allvariantprice = [...variant_price, ...oldprice];
      console.log("allvariantprice", allvariantprice);
      const barcodeDataMap = {}; // Object to store barcode quantities

      // Loop through the arrays and accumulate quantities based on barcodes
      for (let i = 0; i < allbarcodes.length; i++) {
        const barcode = allbarcodes[i];
        const quantity = allquantities[i];
        const cost = allvariantcost[i];
        const variantprice = allvariantprice[i];

        const discount = alldiscounts[i];
        const itemId = allInventoryItem[i];
        const sku = allSkus[i];
        const price = allPrices[i];
        console.log("variantprice", variantprice);

        if (barcodeDataMap[barcode]) {
          barcodeDataMap[barcode].quantity += quantity;
          barcodeDataMap[barcode].cost += parseFloat(cost || 0); // Parse cost to a number and initialize to 0 if it's not defined
          barcodeDataMap[barcode].variantprice += parseFloat(variantprice || 0); // Parse cost to a number and initialize to 0 if it's not defined

          barcodeDataMap[barcode].discount += parseFloat(discount || 0); // Parse discount to a number and initialize to 0 if it's not defined
          barcodeDataMap[barcode].itemId += parseFloat(itemId || 0); // Parse discount to a number and initialize to 0 if it's not defined
          barcodeDataMap[barcode].sku += parseFloat(sku || 0); // Parse cost to a number and initialize to 0 if it's not defined
          barcodeDataMap[barcode].price += parseFloat(price || 0); // Parse cost to a number and initialize to 0 if it's not defined
        } else {
          barcodeDataMap[barcode] = {
            quantity,
            itemId,
            sku,
            price: parseFloat(price || 0), // Initialize cost to 0 if it's not defined
            variantprice: parseFloat(variantprice || 0), // Initialize cost to 0 if it's not defined

            cost: parseFloat(cost || 0), // Initialize cost to 0 if it's not defined
            discount: parseFloat(discount || 0), // Initialize discount to 0 if it's not defined
          };
        }
      }
      console.log("barcodeDataMap", barcodeDataMap);
      const newAllBarcodes = [];
      const newAllVariantPrices = [];

      const newAllQuantities = [];
      const newAllDiscounts = [];
      const newAllCosts = [];
      const newInventoryId = [];
      const newskus = [];
      const newOrigPrices = [];

      // Loop through the combined barcode quantities and add them to new arrays
      for (const barcode in barcodeDataMap) {
        if (barcodeDataMap.hasOwnProperty(barcode)) {
          newAllBarcodes.push(barcode);
          newAllQuantities.push(barcodeDataMap[barcode].quantity);
          newAllCosts.push(barcodeDataMap[barcode].cost);
          newAllVariantPrices.push(barcodeDataMap[barcode].variantprice);

          newAllDiscounts.push(barcodeDataMap[barcode].discount);
          newInventoryId.push(barcodeDataMap[barcode].itemId);
          newskus.push(barcodeDataMap[barcode].sku);
          newOrigPrices.push(barcodeDataMap[barcode].price);
        }
      }
      const newtitles = {}; // Create an object to store titles by barcode

      // Assuming titlesArray and barcodeArray are your arrays
      allTitles.forEach((title, index) => {
        const barcode = newAllBarcodes[index];
        newtitles[barcode] = title;
      });
      const titleArray = Object.values(newtitles);

      console.log("New titles", titleArray);
      console.log("New all skus:", newskus);
      console.log("New all prices:", newOrigPrices);

      console.log("New all barcodes:", newAllBarcodes);
      console.log("New all quantities:", newAllQuantities);
      console.log("New all newAllDiscounts:", newAllDiscounts);
      console.log("New all newAllCosts:", newAllCosts);
      console.log("New all new variant price:", newAllVariantPrices);

      console.log("New all newInventoryId:", newInventoryId);

      const newPrices = newAllBarcodes?.map((selectedProduct, index) => {
        const price = newOrigPrices[index];
        const qty = newAllQuantities[index]; // Get the quantity from qtyList based on index
        const dis = newAllDiscounts[index];
        const discountAmount = price * (dis / 100); // Calculate the discount amount based on the percentage
        const discountedPrice = price - discountAmount; // Calculate the price after discount
        const x = discountedPrice * qty;
        return x;
      });
      console.log("nEW PRICES", newPrices);
      const quantityDifferences = [];

      for (let i = 0; i < newAllBarcodes.length; i++) {
        const barcode = newAllBarcodes[i]; // Use newAllBarcodes here
        const quantity = newAllQuantities[i]; // Use newAllQuantities here
        console.log("oldBarcodes", oldBarcodes);
        const oldOrderIndex = oldBarcodes.indexOf(barcode);
        if (oldOrderIndex !== -1) {
          const oldOrderQuantity = oldquantity[oldOrderIndex];
          console.log("oldBarcodes", oldquantity);

          const difference = quantity - oldOrderQuantity;
          console.log("oldBarcodes", difference);

          quantityDifferences.push({ barcode, difference });
        } else {
          // Barcode not found in oldOrder_barcodes, add with positive quantity
          quantityDifferences.push({ barcode, difference: quantity });
        }
      }

      console.log("Quantity differences:", quantityDifferences);

      const { data } = await axios.put(
        `${baseURL}/api/order/${info?.id}`,
        {
          title: info?.title,
          id: info?.id,
          variant_barcodes: newAllBarcodes,
          variant_quantity: newAllQuantities,
          variant_discount: newAllDiscounts,
          quantityDifferences: quantityDifferences,
          variant_cost: newAllCosts,
          variant_price: newAllVariantPrices,
          variant_inventory_item_id: newInventoryId,
          total_price: totalBill,
          adjusted_price: adjustedPrice,
          paid_price: payable,
          change_price: change_price,
          recieved_price: recieved_price,
          status: status,
          customer: info?.customer,
          order_date: info?.order_date,
          product_discount: info?.product_discount,
          product_quantity: info?.product_quantity,
          products: info?.products,
          product_original_price: newOrigPrices,
          product_newprice: newPrices,
          product_name: titleArray,
          sku: newskus,
          payment_type: info?.payment_type,
          salesman: info?.salesman,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteOrderAction = createAsyncThunk(
  "order/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", id);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(`${baseURL}/api/order/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const orderSlices = createSlice({
  name: "order",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createOrderAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createOrderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.order = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createOrderAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message || "Unknown error occurred";
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchOrdersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchOrdersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchOrdersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(getOrdersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOrdersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.DBorders = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getOrdersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    //Get User By Id
    builder.addCase(getOrderAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getOrderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getOrder = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getOrderAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update User By Id
    builder.addCase(updateOrderAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateOrderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateOrder = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateOrderAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    builder.addCase(updateFBROrderAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateFBROrderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateFBROrder = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateFBROrderAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteOrderAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteOrderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteOrder = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteOrderAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default orderSlices.reducer;
