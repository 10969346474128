import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "../users/userSlices";

const baseURL = process.env.REACT_APP_BASE_URL;

export const createProfileAction = createAsyncThunk(
  "profile/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${baseURL}/api/profile`,
        {
          name: info?.name,
          address: info?.address,
          website: info?.website,
          phone: info?.phone,
          ntn: info?.ntn,
          tax: info?.tax,
          package: info?.package,
          posid: info?.posid,
          terms: info?.terms,
          location: info?.location,
          image: info?.image,
          updateStock: info?.updateStock === "" ? false : info?.updateStock,
          updateCustomer:
            info?.updateCustomer === "" ? false : info?.updateCustomer,
          updateDiscount:
            info?.updateDiscount === "" ? false : info?.updateDiscount,
        },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchProfileAction = createAsyncThunk(
  "profile/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const data = await axios.get(
        `${baseURL}/api/profile`,

        config
      );

      return data?.data;
    } catch (error) {
      console.log("perror", error);
      if (!error?.response) {
        throw error;
      }
      if (error?.response?.status === 500) {
        // Token is expired or invalid, trigger the logout action
        dispatch(logoutUserAction());
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
const profileSlices = createSlice({
  name: "profile",
  initialState: { loading: false, appErr: null, serverErr: null },
  reducers: {
    logoutUser: (state) => {
      // Handle the logout action here
      state.appErr = "Not authorized, token expired or invalid";
      state.serverErr = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProfileAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createProfileAction.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchProfileAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchProfileAction.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
      if (state.appErr === "Not authorized, token expired or invalid") {
        // Dispatch the logout action to log out the user
        action?.thunkAPI?.dispatch(logoutUser());
      }
    });
    builder.addCase(logoutUser, (state) => {
      state.loading = false;
      state.appErr = null;
      state.serverErr = null;
      // ... clear other profile properties if needed
    });
  },
});
export const { logoutUser } = profileSlices.actions;

export default profileSlices.reducer;
