import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const registerUserAction = createAsyncThunk(
  "users/register",
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const { data } = await axios.post(
        `${baseURL}/api/users/register`,
        user,
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const loginUserAction = createAsyncThunk(
  "users/login",
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const { data } = await axios.post(
        `${baseURL}/api/users/login`,
        user,
        config
      );
      console.log("data", data);
      localStorage.setItem("token", data?.token);
      // Check if the server response contains the "Logged out" error message

      // Update the user information in localStorage
      localStorage.setItem("userInfo", JSON.stringify(data));

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const logoutUserAction = createAsyncThunk(
  "users/logout",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const userLoginFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

export const fetchUsersAction = createAsyncThunk(
  "users/fetch",
  async (users, { rejectWithValue, getState, dispatch }) => {
    try {
      const users = getState()?.users;
      const { userAuth } = users;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(`${baseURL}/api/users`, config);
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      if (error?.response?.status === 500) {
        // Token is expired or invalid, trigger the logout action
        dispatch(logoutUserAction());
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUserAction = createAsyncThunk(
  "user/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("sliceid", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/users/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateUserAction = createAsyncThunk(
  "user/update",

  async (user, { rejectWithValue, getState, dispatch }) => {
    console.log("updateMobile", user);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let blocked = user?.isBlocked === "Yes" ? true : false;
      const { data } = await axios.put(
        `${baseURL}/api/users/${user?.id}`,
        {
          //    lastName: user?.lastName,
          role: user?.role,
          isBlocked: blocked,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const forgotUserAction = createAsyncThunk(
  "user/forgot",

  async (user, { rejectWithValue, getState, dispatch }) => {
    console.log("updateMobile", user);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${baseURL}/api/users/forgot`,
        user,
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
const userSlices = createSlice({
  name: "users",
  initialState: {
    userAuth: userLoginFromStorage,
  },
  extraReducers: (builder) => {
    builder.addCase(registerUserAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(registerUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.registered = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(registerUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    builder.addCase(loginUserAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(loginUserAction.fulfilled, (state, action) => {
      state.userAuth = action?.payload;
      state.loading = false;

      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(loginUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    }); //logout user

    builder.addCase(logoutUserAction.pending, (state, action) => {
      state.loading = false;
    });

    builder.addCase(logoutUserAction.fulfilled, (state, action) => {
      state.appError = undefined;
      state.serverError = undefined;
      state.loading = false;
      state.userAuth = null;
    });
    builder.addCase(logoutUserAction.rejected, (state, action) => {
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
      state.loading = false;
    });

    builder.addCase(fetchUsersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUsersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchUsersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    //Get User By Id
    builder.addCase(getUserAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getUser = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update User By Id
    builder.addCase(updateUserAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateUser = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
    builder.addCase(forgotUserAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(forgotUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.forgot = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(forgotUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default userSlices.reducer;
