import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logoutUserAction } from "../users/userSlices";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createProductAction = createAsyncThunk(
  "product/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;

      const payload = {
        product: [
          {
            title: info?.map((x) => x?.title),
            status: info?.map((x) => x?.status),
            tags: info?.map((x) => x?.tags),

            product_type: info?.map((x) => x?.product_type),
            handle: info?.map((x) => x?.handle),
            vendor: info?.map((x) => x?.vendor),
            image: info?.map((x) => x?.image?.replace(undefined, "")),

            barcode: info?.map((x) => x?.barcode),
            sku: info?.map((x) => x?.sku),

            price: info?.map((x) => x?.price),
            compare_at_price: info?.map((x) => x?.compare_at_price),
            inventory_quantity: info?.map((x) => x?.inventory_quantity),
            old_inventory_quantity: info?.map((x) => x?.old_inventory_quantity),

            variant_id: info?.map((x) => x?.variant_id),
            variant_title: info?.map((x) => x?.variant_title),
            variant_option1: info?.map((x) => x?.variant_option1),
            variant_option2: info?.map((x) => x?.variant_option2),
            variant_option3: info?.map((x) => x?.variant_option3),
            variant_price: info?.map((x) => x?.variant_price),
            variant_cost: info?.map((x) => x?.variant_cost),
            variant_inventory_item_id: info?.map(
              (x) => x?.variant_inventory_item_id
            ),

            variant_compare_at_price: info?.map(
              (x) => x?.variant_compare_at_price
            ),

            options_values: info?.map((x) => x?.options_values),
            options_name: info?.map((x) => x?.options_name),
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const response = await axios.post(
        `${baseURL}/api/product`,
        {
          title: payload?.product[0]?.title,
          status: payload?.product[0]?.status,
          tags: payload?.product[0]?.tags,

          product_type: payload?.product[0]?.product_type,
          handle: payload?.product[0]?.handle,
          vendor: payload?.product[0]?.vendor,
          image: payload?.product[0]?.image,

          inventory_quantity: payload?.product[0]?.inventory_quantity,
          price: payload?.product[0]?.price[0],
          compare_at_price: payload?.product[0]?.compare_at_price[0],

          variant_title: payload?.product[0]?.variant_title,
          variant_id: payload?.product[0]?.variant_id,
          variant_option1: payload?.product[0]?.variant_option1,
          variant_option2: payload?.product[0]?.variant_option2,
          variant_option3: payload?.product[0]?.variant_option3,
          variant_cost: payload?.product[0]?.variant_cost,

          variant_inventory_item_id:
            payload?.product[0]?.variant_inventory_item_id,

          variant_price: payload?.product[0]?.variant_price,
          variant_compare_at_price:
            payload?.product[0]?.variant_compare_at_price,
          sku: payload?.product[0]?.sku,

          barcode: payload?.product[0]?.barcode,
          old_inventory_quantity: payload?.product[0]?.old_inventory_quantity,

          options_name: payload?.product[0]?.options_name,
          options_values: payload?.product[0]?.options_values,
        },
        config
      );
      return response.data; // Return the response data
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateProductAction = createAsyncThunk(
  "product/updatesProducts",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const payload = {
        product: [
          {
            //  title: info?.map((x) => x?.title),
            status: info?.map((x) => x?.status),

            //  product_type: info?.map((x) => x?.product_type),
            //handle: info?.map((x) => x?.handle),
            //    vendor: info?.map((x) => x?.vendor),
            //  image: info?.map((x) => x?.image?.replace(undefined, '')),
            tags: info?.map((x) => x?.tags),

            barcode: info?.map((x) => x?.barcode),
            variant_cost: info?.map((x) => x?.variant_cost),
            sku: info?.map((x) => x?.sku),
            price: info?.map((x) => x?.price),
            compare_at_price: info?.map((x) => x?.compare_at_price),
            inventory_quantity: info?.map((x) => x?.inventory_quantity),
            //old_inventory_quantity: info?.map((x) => x?.old_inventory_quantity),

            //variant_id: info?.map((x) => x?.variant_id),
            //variant_title: info?.map((x) => x?.variant_title),
            //variant_option1: info?.map((x) => x?.variant_option1),
            //variant_option2: info?.map((x) => x?.variant_option2),
            //variant_option3: info?.map((x) => x?.variant_option3),
            variant_price: info?.map((x) => x?.variant_price),
            variant_compare_at_price: info?.map(
              (x) => x?.variant_compare_at_price
            ),
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      axios
        .put(
          `${baseURL}/api/product/db`,
          {
            status: payload?.product[0]?.status,

            //  product_type: payload?.product[0]?.product_type,
            //handle: payload?.product[0]?.handle,
            //vendor: payload?.product[0]?.vendor,
            //image: payload?.product[0]?.image,

            //vendor: payload?.product[0]?.vendor,

            inventory_quantity: payload?.product[0]?.inventory_quantity,
            price: payload?.product[0]?.price[0],
            compare_at_price: payload?.product[0]?.compare_at_price[0],

            //variant_title: payload?.product[0]?.variant_title,
            //variant_id: payload?.product[0]?.variant_id,
            //variant_option1: payload?.product[0]?.variant_option1,
            //variant_option2: payload?.product[0]?.variant_option2,
            //variant_option3: payload?.product[0]?.variant_option3,
            variant_cost: payload?.product[0]?.variant_cost,
            tags: payload?.product[0]?.tags,

            variant_price: payload?.product[0]?.variant_price,
            variant_compare_at_price:
              payload?.product[0]?.variant_compare_at_price,
            barcode: payload?.product[0]?.barcode,
            sku: payload?.product[0]?.sku,

            // old_inventory_quantity: payload?.product[0]?.old_inventory_quantity,

            //options_name: payload?.product[0]?.options_name,
            //options_values: payload?.product[0]?.options_values,
          },
          config
        )
        .then((response) => {
          console.log("getProduct", payload);

          //   console.log(response);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchProductsAction = createAsyncThunk(
  "product/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("info", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/product`,

        config
      );
      console.log("data", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      if (error?.response?.status === 500) {
        // Token is expired or invalid, trigger the logout action
        dispatch(logoutUserAction());
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchProductsByDbAction = createAsyncThunk(
  "product/fetchDb",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("fetchDb", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/product/db`,

        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchAllProductsByDbAction = createAsyncThunk(
  "product/fetchDbAll",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/product/dbAll`,

        config
      );
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getProductAction = createAsyncThunk(
  "product/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("id", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(
        `${baseURL}/api/product/db/${id}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getProductActionBarcode = createAsyncThunk(
  "productbarcode/get",

  async (barcode, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("barcode", barcode);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(
        `${baseURL}/api/product/db/barcode/${barcode}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteProductAction = createAsyncThunk(
  "product/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", id);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
           Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(
        `${baseURL}/api/product/${id}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const postBarcodesAction = createAsyncThunk(
  "product/update",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      // Implement rate limiting to prevent too many requests in a short time
      const throttle = createThrottle(6, 1000); // Allow 2 requests per second

      const user = getState()?.users;
      const { userAuth } = user;

      const variantIds = info?.map((item) => item?.variant_id);
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
      };
      const flattenedVariantIds = variantIds.flat();

      const responses = [];
      for (const variantId of flattenedVariantIds) {
        await throttle(); // Introduce the delay
        try {
          const response = await axios.put(
            `${baseURL}/api/product/barcode`,
            { variant_id: variantId },
            config
          );
          responses.push(response);
        } catch (error) {
          console.log("Error:", error.message);
          responses.push(null); // Handle the error and continue
        }
      }

      return responses;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const postSingleBarcodesAction = createAsyncThunk(
  "productsinglebarcode/update",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      // Implement rate limiting to prevent too many requests in a short time
      const throttle = createThrottle(10, 1000); // Allow 2 requests per second

      const user = getState()?.users;
      const { userAuth } = user;

      const variantIds = info.map((item) => item.variant_id);
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
      };
      const flattenedVariantIds = variantIds.flat();

      const responses = [];
      for (const variantId of flattenedVariantIds) {
        await throttle(); // Introduce the delay
        try {
          const response = await axios.put(
            `${baseURL}/api/product/singlebarcode`,
            { variant_id: variantId },
            config
          );
          responses.push(response.data);
        } catch (error) {
          console.log("Error:", error.message);
          responses.push(null); // Handle the error and continue
        }
      }

      return responses;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const postCostPriceAction = createAsyncThunk(
  "productcost/update",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      // Implement rate limiting to prevent too many requests in a short time
      //const throttle = createThrottle(20, 1000); // Allow 2 requests per second
      // SHOPIFY API LIMIT 20 requests/second

      const user = getState()?.users;
      const { userAuth } = user;

      // Assuming your item has a property called "status"
      const filteredInfo = info?.filter((item) => item?.status !== "archived");
      const product_id = filteredInfo.map((item) => item?._id);

      // Rest of your code...

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
      };
      const flattenedVariantIds = product_id?.flat();

      console.log("slice", flattenedVariantIds);
      const responses = [];
      for (const inventoryID of flattenedVariantIds) {
        // await throttle(); // Introduce the delay

        try {
          const response = await axios.put(
            `${baseURL}/api/product/cost`,
            { product_id: inventoryID },
            config
          );
          responses.push(response.data);
        } catch (error) {
          console.log("Error:", error.message);
          responses.push(null); // Handle the error and continue
        }
      }

      return responses;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
// Throttle function to limit the number of requests per time interval
const createThrottle = (maxRequests, interval) => {
  let requests = 0;
  return async () => {
    if (requests >= maxRequests) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      requests = 0;
    }
    requests++;
  };
};

export const putShopifyProductsAction = createAsyncThunk(
  "product/shopify",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("info", info);

      const title = info?.map((x) => x?.title);

      const handle = info?.map((x) => x?.handle);
      const barcode = info?.map((x) => x?.barcode);
      const variant_inventory_item_id = info?.map(
        (x) => x?.variant_inventory_item_id
      );
      const variant_id = info?.map((x) => x?.variant_id);
      const inventory_quantity = info?.map((x) => x?.inventory_quantity);

      const payload = {
        product: {
          title: title,
          handle: handle,
          barcode: barcode,
          variant_id: variant_id,
          variant_inventory_item_id: variant_inventory_item_id,
          inventory_quantity: inventory_quantity,
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      console.log("payload", payload);
      try {
        const response = await axios.put(
          `${baseURL}/api/product/shopify`,

          {
            title: payload?.product?.title,

            handle: payload?.product?.handle,

            inventory_quantity: payload?.product?.inventory_quantity,

            variant_id: payload?.product?.variant_id,

            variant_inventory_item_id:
              payload?.product?.variant_inventory_item_id,

            barcode: payload?.product?.barcode,
          },
          config
        );

        return response.data;
      } catch (ee) {
        console.log("Error:", ee);
        throw ee;
      }
    } catch (error) {
      console.log("Error:", error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCostPriceAction = createAsyncThunk(
  "productcosting/update",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      // Implement rate limiting to prevent too many requests in a short time
      //const throttle = createThrottle(20, 1000); // Allow 2 requests per second
      // SHOPIFY API LIMIT 20 requests/second
      console.log("info", info);
      const user = getState()?.users;
      const { userAuth } = user;

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
      };
      const body = { barcode: info?.barcode, cost: info?.updateCost };
      try {
        const response = await axios.put(
          `${baseURL}/api/product/costprice`,
          body, // Combine barcode and cost in an object

          config
        );
        return response.data;
      } catch (error) {
        console.log("Error:", error.message);
      }
    } catch (error) {
      console.log(error);

      return rejectWithValue(error?.response?.data);
    }
  }
);

const productSlices = createSlice({
  name: "product",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createProductAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createProductAction.fulfilled, (state, action) => {
      state.loading = false;
      state.product = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createProductAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchProductsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchProductsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchProductsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchAllProductsByDbAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllProductsByDbAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Alldbproducts = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchAllProductsByDbAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchProductsByDbAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchProductsByDbAction.fulfilled, (state, action) => {
      state.loading = false;
      state.dbproducts = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchProductsByDbAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    builder.addCase(updateProductAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateProductAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateProductsAll = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateProductAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Get   By Id
    builder.addCase(getProductAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getProductAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getProduct = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getProductAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
    //Get   By Barcode
    builder.addCase(getProductActionBarcode.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getProductActionBarcode.fulfilled, (state, action) => {
      state.loading = false;
      state.getProductBarcode = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getProductActionBarcode.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
    //Delete
    builder.addCase(deleteProductAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteProductAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteProduct = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteProductAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
    builder.addCase(postBarcodesAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(postBarcodesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateBarcode = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(postBarcodesAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    builder.addCase(postSingleBarcodesAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(postSingleBarcodesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateSingleBarcode = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(postSingleBarcodesAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
    builder.addCase(postCostPriceAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(postCostPriceAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateCost = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(postCostPriceAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    builder.addCase(updateCostPriceAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateCostPriceAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateCostPrice = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateCostPriceAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
    builder.addCase(putShopifyProductsAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(putShopifyProductsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateShopify = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(putShopifyProductsAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default productSlices.reducer;
