import React from "react";

function ThemedSuspense() {
  return (
    <div className="flex items-center justify-center h-full backdrop-filter backdrop-blur">
      <img
        src="/loader.gif"
        className="m-auto rounded-full w-64 h-64 drop-shadow-2xl"
        alt="Loading"
      />
    </div>
  );
}

export default ThemedSuspense;
