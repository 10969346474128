import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/users/userSlices";

import productSlices from "../slices/products/productSlices";
import customerSlices from "../slices/customers/customerSlices";
import orderSlices from "../slices/orders/orderSlices";
import profileSlices from "../slices/profile/profileSlices";
import salesmanSlices from "../slices/salesman/salesmanSlices";

const store = configureStore({
  reducer: {
    users: userReducer,

    products: productSlices,
    customers: customerSlices,
    orders: orderSlices,
    profile: profileSlices,
    salesman: salesmanSlices,
  },
});

export default store;
